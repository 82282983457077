import getHosts from "actions/getHosts";
import CardAuthorBox from "components/CardAuthorBox/CardAuthorBox";
import CardAuthorBox2 from "components/CardAuthorBox2/CardAuthorBox2";
import Heading from "components/Heading/Heading";
import { DEMO_AUTHORS } from "data/authors";
import { AuthorType } from "data/types";
import useRegisterModal from "hooks/useRegisterModal";
import React, { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { User } from "Types/User";
import { UserContext } from "UserContext";

export interface SectionGridAuthorBoxProps {
  className?: string;
  authors?: AuthorType[];
  boxCard?: "box1" | "box2";
  gridClassName?: string;
}

const DEMO_DATA = DEMO_AUTHORS.filter((_, i) => i < 10);

const SectionGridAuthorBox: FC<SectionGridAuthorBoxProps> = ({
  className = "",
  authors = DEMO_DATA,
  boxCard = "box1",
  gridClassName = "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 ",
}) => {
  const { isAuthenticated } = useContext(UserContext);
  const registerModal = useRegisterModal();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [hosts, setHosts] = useState<User[]>([
    { id: 1, email: "", name: "", tel: "", createdOn: "" },
  ]);
  async function fetchHosts() {
    try {
      const hostsData = await getHosts();
      if (hostsData) {
        setTimeout(() => {
          setIsLoaded(true);
        }, 50);
        setHosts(hostsData);
      } else {
        setHosts([]);
      }
    } catch (error) {
      setHosts([]);
    }
  }
  useEffect(() => {
    fetchHosts();
  }, []);
  const navigate = useNavigate();
  return (
    <div
      className={`nc-SectionGridAuthorBox relative ${className}`}
      data-nc-id="SectionGridAuthorBox"
    >
      <Heading desc="Rating based on properties listed" isCenter>
        Top 5 Hotel Real Estate Developers of the Month
      </Heading>
      <div className={`grid gap-6 md:gap-8 ${gridClassName}`}>
        {hosts.map((author, index) => (
          <div
            onClick={() => {
              navigate(`/profile/${author.name}?id=${author.id}`);
            }}
          >
            <CardAuthorBox
              index={index < 3 ? index + 1 : undefined}
              key={author.id}
              isLoaded={isLoaded}
              author={author}
            />
          </div>
        ))}
      </div>
      <div className="mt-16 flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-5">
        {!isAuthenticated ? (
          <ButtonPrimary
            onClick={() => {
              registerModal.onOpen();
            }}
          >
            If you're a hotel looking to sell properties, join TryBuy.club today!
          </ButtonPrimary>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SectionGridAuthorBox;
