import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

export interface PageSellerGuidesProps {
  className?: string;
}

const HotelGuides: FC<PageSellerGuidesProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageSellerGuides overflow-hidden relative ${className}`}
      data-nc-id="PageSellerGuides"
    >
      <Helmet>
        <title>Hotel Guide / Seller Guide - TryBuy.club</title>
      </Helmet>

      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div className="py-16 lg:py-28 space-y-8 lg:space-y-12">
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            Hotel Guide / Seller Guide
          </h2>

          <p>
            Welcome to TryBuy.club, the ultimate platform for hotel property investments. Whether
            you're a hotel owner looking to sell a property or a new hotel seeking to join the
            platform, we make the process simple, fast, and effective.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            Why Choose TryBuy.club?
          </h3>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">
            Access to Global Investors:
          </h4>
          <p>
            When you list your hotel or investment property on TryBuy.club, you open the door to a
            global community of investors who are actively looking for opportunities like yours. Our
            platform connects sellers with qualified buyers around the world.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Curated Listings:</h4>
          <p>
            We ensure that only high-quality hotel properties are featured on the platform. This
            means that your property will be part of a curated selection, increasing its visibility
            among serious investors who are looking for profitable opportunities.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Increased Exposure:</h4>
          <p>
            With TryBuy.club, your property will be showcased in front of an international audience,
            helping you reach more potential buyers than traditional selling methods ever could.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Easy Listing Process:</h4>
          <p>
            Adding your property to TryBuy.club is quick and easy. Simply provide key details,
            upload high-quality photos, and let us take care of the rest. Once your property is
            listed, it’s available for investors to browse instantly.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">
            Comprehensive Property Information:
          </h4>
          <p>
            We provide investors with all the information they need to make an informed decision,
            including detailed descriptions, photos, financials, and return on investment potential.
            You can ensure your listing is optimized to attract serious buyers.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Try Before You Sell:</h4>
          <p>
            Investors who are interested in your property can reserve a stay at your hotel through
            the platform before making a purchase. This gives them the chance to experience the
            property firsthand, increasing the likelihood of a successful sale.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">
            Secure Reservation and Purchase Requests:
          </h4>
          <p>
            While payments for the property and rental are handled directly between the buyer and
            the hotel, all reservations and purchase requests are processed through TryBuy.club,
            ensuring transparency and ease of communication between both parties.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Expert Support:</h4>
          <p>
            Our team of professionals is available to assist you at every step of the process.
            Whether you need help with listing your property, marketing, or negotiating with
            potential buyers, we are here to support you.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">How It Works:</h3>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">
            Sign Up and List Your Property:
          </h4>
          <p>
            Create an account and submit your hotel or investment property details to be featured on
            the platform.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">
            Attract Global Buyers:
          </h4>
          <p>
            Once your listing is live, you can begin receiving inquiries from investors around the
            world.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Engage with Buyers:</h4>
          <p>
            Respond to inquiries and arrange for potential buyers to visit the property or reserve a
            stay before finalizing their decision.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Close the Sale:</h4>
          <p>
            When you’ve found the right buyer, the transaction will be completed directly between
            you and the buyer, while TryBuy.club facilitates the reservation and purchase request
            process.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            Start Listing Your Property Today
          </h3>
          <p>
            Ready to take your hotel property to the global market? Join TryBuy.club today and take
            advantage of our streamlined, efficient process that connects sellers with investors.
            Let us help you find the right buyer for your property with ease and confidence.
          </p>
        </div>

        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageSellerGuides_" />
        </div>

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default HotelGuides;
