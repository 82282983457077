import React, { Fragment, FC, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { GuestsObject } from "components/HeroSearchForm/type";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
const translationMap = {
  en: {
    adults: {
      label: "Adults",
      description: "Ages 13 or above",
    },
    children: {
      label: "Children",
      description: "Ages 2–12",
    },
    infants: {
      label: "Infants",
      description: "Ages 0–2",
    },
    guests: "Guests"
  },
  fr: {
    adults: {
      label: "Adultes",
      description: "Ages 13 ans et plus",
    },
    children: {
      label: "Enfants",
      description: "Ages 2 à 12 ans",
    },
    infants: {
      label: "Bébés",
      description: "Ages 0 à 2 ans",
    },
  },
  it: {
    adults: {
      label: "Adulti",
      description: "Età 13 o più",
    },
    children: {
      label: "Bambini",
      description: "Età 2–12",
    },
    infants: {
      label: "Neonati",
      description: "Età 0–2",
    },
  },
  es: {
    adults: {
      label: "Adultos",
      description: "Edades 13 o más",
    },
    children: {
      label: "Niños",
      description: "Edades 2–12",
    },
    infants: {
      label: "Bebés",
      description: "Edades 0–2",
    },
  },
  tr: {
    adults: {
      label: "Yetişkinler",
      description: "13 yaş ve üzeri",
    },
    children: {
      label: "Çocuklar",
      description: "2–12 yaş",
    },
    infants: {
      label: "Bebekler",
      description: "0–2 yaş",
    },
  },
  ru: {
    adults: {
      label: "Взрослые",
      description: "Возраст 13 лет и старше",
    },
    children: {
      label: "Дети",
      description: "Возраст 2–12 лет",
    },
    infants: {
      label: "Младенцы",
      description: "Возраст 0–2 года",
    },
  },
  de: {
    adults: {
      label: "Erwachsene",
      description: "Ab 13 Jahren",
    },
    children: {
      label: "Kinder",
      description: "Alter 2–12",
    },
    infants: {
      label: "Säuglinge",
      description: "Alter 0–2",
    },
  },
  bg: {
    adults: {
      label: "Възрастни",
      description: "На възраст 13 или повече",
    },
    children: {
      label: "Деца",
      description: "На възраст 2–12",
    },
    infants: {
      label: "Бебета",
      description: "На възраст 0–2",
    },
  },
  pl: {
    adults: {
      label: "Dorośli",
      description: "Wiek 13 lub więcej",
    },
    children: {
      label: "Dzieci",
      description: "Wiek 2–12",
    },
    infants: {
      label: "Niemowlęta",
      description: "Wiek 0–2",
    },
  },
};
export interface GuestsInputProps {
  className?: string;
  handleChangeOfGuests: (
    totalGuests: number,
    guestAdults: number,
    guestChildren: number,
    guestInfants: number
  ) => void;
  adultGuestParam: number;
  childrenGuestsParam: number;
  infantGuestsParam: number;
}

const GuestsInput: FC<GuestsInputProps> = ({
  className = "flex-1",
  handleChangeOfGuests,
  adultGuestParam,
  childrenGuestsParam,
  infantGuestsParam,
}) => {
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(1);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(0);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(0);
  const [currentLanguage, setCurrentLanguage] = useState("en");
  useEffect(() => {
    setGuestAdultsInputValue(adultGuestParam);
    setGuestChildrenInputValue(childrenGuestsParam);
    setGuestInfantsInputValue(infantGuestsParam);
  }, [adultGuestParam, childrenGuestsParam, infantGuestsParam]);
  useEffect(() => {
    setCurrentLanguage(localStorage.getItem("selectedLanguage") || "en");
  }, []);
  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      guestAdults: guestAdultsInputValue,
      guestChildren: guestChildrenInputValue,
      guestInfants: guestInfantsInputValue,
    };
    if (type === "guestAdults") {
      setGuestAdultsInputValue(value);
      newValue.guestAdults = value;
    }
    if (type === "guestChildren") {
      setGuestChildrenInputValue(value);
      newValue.guestChildren = value;
    }
    if (type === "guestInfants") {
      setGuestInfantsInputValue(value);
      newValue.guestInfants = value;
    }
    handleChangeOfGuests(
      newValue.guestChildren + newValue.guestAdults + newValue.guestInfants,
      newValue.guestAdults,
      newValue.guestChildren,
      newValue.guestInfants
    );
  };
  const totalGuests = guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;
  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 flex items-center focus:outline-none rounded-b-3xl ${
              open ? "shadow-lg" : ""
            }`}
          >
            <Popover.Button
              className={`relative z-10 flex-1 flex text-left items-center p-3 space-x-3 focus:outline-none`}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">{totalGuests || ""} Guests</span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  {totalGuests ? "Guests" : "Add guests"}
                </span>
              </div>

              {!!totalGuests && open && (
                <ClearDataButton
                  onClick={() => {
                    handleChangeOfGuests(1, 0, 0, 0);
                  }}
                />
              )}
            </Popover.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl ring-1 ring-black ring-opacity-5 ">
              <NcInputNumber
                className="w-full"
                defaultValue={guestAdultsInputValue}
                onChange={(value) => handleChangeData(value, "guestAdults")}
                max={10}
                min={1}
                //@ts-ignore
                label={translationMap[currentLanguage].adults.label}
                //@ts-ignore
                desc={translationMap[currentLanguage].adults.description}
              />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={guestChildrenInputValue}
                onChange={(value) => handleChangeData(value, "guestChildren")}
                max={4}
                //@ts-ignore
                label={translationMap[currentLanguage].children.label}
                //@ts-ignore
                desc={translationMap[currentLanguage].children.description}
              />

              <NcInputNumber
                className="w-full mt-6"
                defaultValue={guestInfantsInputValue}
                onChange={(value) => handleChangeData(value, "guestInfants")}
                max={4}
                //@ts-ignore
                label={translationMap[currentLanguage].infants.label}
                //@ts-ignore
                desc={translationMap[currentLanguage].infants.description}
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
