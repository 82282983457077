import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

export interface PageSellerGuidesProps {
  className?: string;
}

const FeaturesP: FC<PageSellerGuidesProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageSellerGuides overflow-hidden relative ${className}`}
      data-nc-id="PageSellerGuides"
    >
      <Helmet>
        <title>About Us - TryBuy.club</title>
      </Helmet>
      <BgGlassmorphism />
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div className="py-16 lg:py-28 space-y-8 lg:space-y-12">
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">About Us</h2>
          <p>
            Welcome to TryBuy.club, the platform designed to revolutionize the way people invest in
            hotel properties worldwide. Whether you’re a property buyer looking for investment
            opportunities or a hotel owner seeking to sell properties within your hotel, we provide
            an easy-to-use platform to connect buyers and sellers.
          </p>
          <p>
            Our mission is to create a global network that bridges the gap between property buyers
            and sellers while providing an intuitive, seamless experience for all involved. We
            believe that investing in hotel properties should be straightforward, accessible, and
            secure.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">For Property Buyers</h3>

          <p>
            <strong>Find Attractive Investment Properties in Hotels Worldwide with Ease</strong>
          </p>
          <p>
            At TryBuy.club, we make it easier than ever for investors to discover and invest in
            hotel properties across the globe. Our platform is designed with simplicity and
            efficiency in mind, so you can quickly and confidently find investment opportunities
            that align with your goals.
          </p>

          <p>
            <strong>Why TryBuy.club?</strong>
          </p>
          <p>
            <strong>Global Access:</strong> Browse a diverse selection of investment properties in
            hotels located in prime destinations worldwide. Whether you're looking for properties in
            bustling cities, exotic resorts, or up-and-coming locations, TryBuy.club connects you to
            the best options.
          </p>
          <p>
            <strong>Curated Listings:</strong> We only feature carefully selected hotel properties
            with high potential for growth. Our platform showcases properties with excellent
            returns, proven performance, and strong investment potential.
          </p>
          <p>
            <strong>Easy-to-Use Search Filters:</strong> Our intuitive search functionality allows
            you to filter properties based on location, price range, hotel type, and investment
            goals. With just a few clicks, you can narrow down your search to the most suitable
            options.
          </p>
          <p>
            <strong>Detailed Property Information:</strong> Each listing includes comprehensive
            details about the hotel, the investment property, and the potential return on
            investment. View photos, financials, and key information to make informed decisions
            without the hassle.
          </p>
          <p>
            <strong>Try Before You Buy:</strong> We understand that making a property investment is
            a big decision. That’s why we offer the opportunity to reserve a stay in the property
            you’re interested in before making your purchase. Experience the property firsthand,
            enjoy the amenities, and get a feel for the investment before committing.
          </p>
          <p>
            <strong>Expert Guidance:</strong> If you need assistance, our team of professionals is
            available to help you navigate through the platform, answer your questions, and provide
            expert advice on your investment journey.
          </p>
          <p>
            <strong>Start Exploring Today</strong>
          </p>
          <p>
            Finding the perfect hotel investment property has never been easier. Sign up for
            TryBuy.club today and start exploring a world of opportunities, all in one place.
            Whether you’re an experienced investor or new to the industry, our platform helps you
            make smarter investment choices and achieve your financial goals.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            For Property Sellers/Hotels
          </h3>

          <p>
            <strong>Expand Your Reach and List Your Property</strong>
          </p>
          <p>
            For hotels and property sellers, TryBuy.club provides a unique opportunity to list and
            sell properties to a global audience. We help you market your property to potential
            investors looking for new opportunities.
          </p>
          <p>
            <strong>Represent Your Hotel</strong>
          </p>
          <p>
            Representing your hotel on TryBuy.club is an easy and effective way to showcase your
            property. Our platform enables you to highlight the features and investment potential of
            your hotel to attract the right buyers.
          </p>
          <p>
            <strong>List Your Property</strong>
          </p>
          <p>
            Simply create an account and list your property with detailed information, including
            high-quality images and descriptions. Once listed, your property will be visible to
            investors worldwide, making it easier for you to find buyers.
          </p>
          <p>
            <strong>Engagement</strong>
          </p>
          <p>
            Engage with interested buyers directly through the platform. You’ll have the opportunity
            to answer questions, schedule property viewings, and discuss the investment potential of
            your property with qualified investors.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            For Both Property Buyers and Sellers
          </h3>
          <p>
            <strong>Join a Trusted Community of Investors and Sellers</strong>
          </p>
          <p>
            Whether you are a buyer looking for a profitable investment or a seller aiming to find
            qualified buyers for your hotel property, TryBuy.club is here to help. Our platform
            serves as a trusted marketplace where buyers and sellers can connect, collaborate, and
            close deals efficiently.
          </p>
          <p>
            <strong>Easy Transactions</strong>
          </p>
          <p>
            Our platform simplifies the process of buying and selling properties by providing
            secure, transparent real-time communication, and making the entire process smooth and
            hassle-free for both parties.
          </p>
          <p>
            <strong>Global Reach</strong>
          </p>
          <p>
            With a global audience of buyers and sellers, TryBuy.club offers a unique opportunity to
            explore international investment options and expand your network in the real estate
            market. Whether you’re buying or selling, you gain access to a wide pool of
            opportunities.
          </p>

          <p>
            At TryBuy.club, we are committed to making your property investment experience as
            seamless and rewarding as possible. Join us today and become a part of the global
            property investment revolution.
          </p>
        </div>

        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageSellerGuides_" />
        </div>
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default FeaturesP;
