import React, { Fragment, useState, FC, useEffect, useMemo } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { eachDayOfInterval } from "date-fns";
export interface Reservation {
  id: number;
  startDate: Date;
  endDate: Date;
  listingId: number;
  renterId: number;
  pricePerNight: number;
}
export interface StayDatesRangeInputProps {
  className?: string;
  onNumberOfNightsChange: (
    numberOfNights: number,
    startDate: Date | null,
    endDate: Date | null
  ) => void;
  monthsShown?: number;
  reservations: Reservation[];
  checkIn: Date;
  checkOut: Date;
}
const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "flex-1",
  onNumberOfNightsChange,
  reservations,
  checkIn,
  checkOut,
  monthsShown,
}) => {
  const disabledDates = useMemo(() => {
    let dates: Date[] = [];
    reservations.forEach((reservation) => {
      const range = eachDayOfInterval({
        start: new Date(reservation.startDate),
        end: new Date(reservation.endDate),
      });
      dates = [...dates, ...range];
    });
    return dates;
  }, [reservations]);
  const getCurrentLanguage = () => {
    // Assuming gtranslateSettings holds the current language code
    //@ts-ignore
    console.log(window.gtranslateSettings);
    //@ts-ignore
    return window.gtranslateSettings ? window.gtranslateSettings.default_language : "en"; // Default to "en"
  };
  const [startDate, setStartDate] = useState<Date | null>(new Date(checkIn));
  const [endDate, setEndDate] = useState<Date | null>(new Date(checkOut));
  const [currentLang, setCurrentLang] = useState<string>("en-us");
  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    getCurrentLanguage();
  }, []);
  useEffect(() => {
    const storedLang = localStorage.getItem("selectedLanguage");
    if (storedLang) {
      if (storedLang == "auto") {
        setCurrentLang("en-us");
      } else {
        setCurrentLang(storedLang);
      }
    } else {
      console.log("No language found, defaulting to 'en'");
    }
  }, []);
  const calculateNumberOfNights = () => {
    if (startDate && endDate) {
      const timeDifference = endDate.getTime() - startDate.getTime();
      const numberOfNights = Math.ceil(timeDifference / (1000 * 3600 * 24));
      return numberOfNights;
    }
    return 0;
  };

  useEffect(() => {
    const nights = calculateNumberOfNights();
    onNumberOfNightsChange(nights, startDate, endDate);
  }, [startDate, endDate, onNumberOfNightsChange]);
  const renderInput = () => {
    return (
      <>
        <div data-no-translate className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {startDate?.toLocaleDateString(currentLang, {
              month: "short",
              day: "2-digit",
            }) || "Add dates"}
            {endDate
              ? " - " +
                endDate?.toLocaleDateString(currentLang, {
                  month: "short",
                  day: "2-digit",
                })
              : ""}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Check in - Check out"}
          </span>
        </div>
      </>
    );
  };

  return (
    <Popover className={`StayDatesRangeInput z-10 relative flex ${className}`}>
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex-1 flex relative p-3 items-center space-x-3 focus:outline-none ${
              open ? "shadow-lg" : ""
            }`}
          >
            {renderInput()}
            {startDate && open && <ClearDataButton onClick={() => onChangeDate([null, null])} />}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-auto xl:-right-10 right-0 z-10 mt-3 top-full w-screen max-w-sm px-4 sm:px-0 lg:max-w-3xl">
              <div className=" overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                <DatePicker
                  selected={startDate}
                  onChange={onChangeDate}
                  startDate={startDate}
                  minDate={new Date()}
                  endDate={endDate}
                  excludeDates={disabledDates}
                  selectsRange
                  monthsShown={monthsShown || 2}
                  showPopperArrow={false}
                  inline
                  renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
                  renderDayContents={(day, date: Date) => (
                    <DatePickerCustomDay
                      dayOfMonth={day}
                      date={date}
                      isExcluded={disabledDates.some(
                        (disabledDate) => disabledDate.getTime() === date.getTime()
                      )}
                    />
                  )}
                />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default StayDatesRangeInput;
