import React from "react";
import { Route, Routes } from "react-router-dom";
import { BookListingProvider } from "BookListingContext";
import UserContextProvider from "UserContext";
import axios from "axios";
import { IS_PRODUCTION } from "config/config";
import AccountPage from "containers/AccountPage/AccountPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import MyListingsPage from "containers/AuthorPage/MyListingsPage";
import PageBuyerGuides from "containers/PageAbout/BuyerGuidesPage";
import ComingSoonPage from "containers/PageAbout/ComingSoonPage";
import PageJoinUs from "containers/PageAbout/JoinUsAsPartnersPage";
import PageAccountPayment from "containers/PageAbout/PageAccountPayment";
import PageCheckAccount from "containers/PageAbout/PageCheckAccount";
import FaqPage from "containers/PageAbout/PageFaq";
import PrivacyPolicy from "containers/PageAbout/PrivacyPolicyPage";
import PageSecurityTips from "containers/PageAbout/SecurityTipsPage";
import PageSellerGuides from "containers/PageAbout/SellerGuidesPage";
import TermsPage from "containers/PageAbout/TermsPage";
import PageTrainingResources from "containers/PageAbout/TrainingResourcesPage";
import PageContact from "containers/PageContact/PageContact";
import PayPage from "containers/PayPage/PayPage";
import MainLayout from "layouts/MainLayout";
import CheckOutPagePageMain from "pages/BookListingPage";
import BookingConfirmation from "pages/BookingConfirmation";
import HomePage from "pages/HomePage";
import ListingStayDetailPage from "pages/ListingStayDetailPage";
import ListingMap from "pages/ListingsMap";
import PageSupport from "containers/PageAbout/FooterPages/SupportPage";
import TermsAndConditions from "containers/PageAbout/FooterPages/TermsAndConditionsPage";
import BuyerGuides from "containers/PageAbout/FooterPages/BuyerGuidesPage";
import SellerGuidesPage from "containers/PageAbout/SellerGuidesPage";
import SecurityTips from "containers/PageAbout/FooterPages/SecurityTipsPage";
import AboutUs from "containers/PageAbout/FooterPages/AboutUsPage";
import AdvertiseOnTryBuy from "containers/PageAbout/FooterPages/AdvertiseOnTruBuyPage";
import FeaturesP from "containers/PageAbout/FooterPages/FeaturesPage";
import JoinUsAsPartners from "containers/PageAbout/FooterPages/JoinUsAsPartnersPage";
import HotelGuides from "containers/PageAbout/FooterPages/HotelGuides";
import PrivacyPolicyPage from "containers/PageAbout/FooterPages/PrivacyPolicyPage";

axios.defaults.baseURL = IS_PRODUCTION ? "https://api.trybuy.club" : "http://localhost:3001";

function App() {
  return (
    <UserContextProvider>
      <BookListingProvider>
        {/* Add the translation widget here */}
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<HomePage />} />
            <Route path="/listings/:id" element={<ListingStayDetailPage />} />
            <Route path="/book-listing/:listingId" element={<CheckOutPagePageMain />} />
            <Route path="/book-listing/:listingId/confirmation" element={<BookingConfirmation />} />
            <Route path="/listings" element={<ListingMap />} />
            <Route path="/hotel-guides" element={<HotelGuides />} />
            <Route path="/training-resources" element={<PageTrainingResources />} />
            <Route path="/buyer-guides" element={<BuyerGuides />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/check-account-type-and-approval" element={<PageCheckAccount />} />
            <Route path="/payment-of-plan" element={<PageAccountPayment />} />
            <Route path="/contacts" element={<PageContact />} />
            <Route path="/join-us-as-partners" element={<PageJoinUs />} />
            <Route path="/profile/:name" element={<AuthorPage />} />
            <Route path="/coming-soon" element={<ComingSoonPage />} />
            <Route path="/my-listings" element={<MyListingsPage />} />
            <Route path="/support-how-to" element={<PageSupport />} />
            <Route path="/security-tips" element={<SecurityTips />} />
            <Route path="/advertise-on-try-buy" element={<AdvertiseOnTryBuy />} />
            <Route path="/try-buy-features" element={<FeaturesP />} />
            <Route path="/join-us-as-partners" element={<JoinUsAsPartners />} />
          </Route>
        </Routes>
      </BookListingProvider>
    </UserContextProvider>
  );
}
// {
//   id: "5",
//   title: "Getting started",
//   menus: [
//     { href: "signin", label: "Sign in | Create account" },
//     { href: "/support-how-to", label: "Support | How to TryBuy" },
//     { href: "/seller-guides", label: "Seller guides" },
//     { href: "/buyer-guides", label: "Buyer guides" },
//     { href: "/security-tips", label: "Security Tips" },
//   ],
// },
// {
//   id: "1",
//   title: "Explore",
//   menus: [
//     { href: "/faq", label: "FAQ" },
//     { href: "/about", label: "About Us" },
//     { href: "/contacts", label: "Contact Information" },
//     { href: "/terms", label: "Terms And Conditions" },
//     { href: "/privacy-policy", label: "Privacy Policy" },
//   ],
// },
// {
//   id: "2",
//   title: "Resources",
//   menus: [
//     { href: "/advertise-on-try-buy", label: "Advertise on TryBuy.club" },
//     { href: "/try-buy-features", label: "Features" },
//     { href: "/join-us-as-partners", label: "Join Us as Partners" },
//   ],
// },
export default App;
