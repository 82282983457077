import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

export interface PageSellerGuidesProps {
  className?: string;
}

const JoinUsAsPartners: FC<PageSellerGuidesProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageSellerGuides overflow-hidden relative ${className}`}
      data-nc-id="PageSellerGuides"
    >
      <Helmet>
        <title>Become a Local Partner - TryBuy.club</title>
      </Helmet>
      <BgGlassmorphism />
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div className="py-16 lg:py-28 space-y-8 lg:space-y-12">
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            Become a Local Partner with TryBuy.club
          </h2>
          <p>
            Are you ready to take the lead in expanding TryBuy.club in your region? Become our
            official local partner and unlock exciting opportunities for growth, while helping
            hotels and property investors connect through our platform.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            What You’ll Do as a Local Partner:
          </h3>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              <strong>Find and Attract Hotels:</strong> Search for hotels in your area and help them
              join the TryBuy.club platform.
            </li>
            <li>
              <strong>Assist Hotels:</strong> Guide hotels through the registration process and
              ensure they make the most of the platform to list their investment properties.
            </li>
            <li>
              <strong>Track and Coordinate Sales:</strong> Support your hotels throughout the
              property listing process, ensuring smooth sales through the platform.
            </li>
          </ul>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            What You’ll Get in Return:
          </h3>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              <strong>Commissions:</strong> on Entry Fees and Property Sales
            </li>
            <li>
              <strong>Notifications of Payments:</strong> Stay updated with timely notifications of
              payments received from the hotels you have attracted to the platform.
            </li>
          </ul>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">Additional Benefits:</h3>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              <strong>Marketing Support:</strong> Use TryBuy.club's branding, including the official
              logo, to promote the platform in your region. If you have an office, display the logo
              in a visible location to reinforce our partnership.
            </li>
            <li>
              <strong>Exclusive Access to Tools:</strong> Gain access to dedicated systems to track
              your progress, monitor sales, and ensure transparency.
            </li>
          </ul>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">Why Join Us?</h3>
          <p>
            By partnering with TryBuy.club, you’ll be part of a growing global network of trusted
            partners helping investors discover unique opportunities in the hospitality industry.
            This is your chance to grow with us as we expand internationally!
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">How to Get Started:</h3>
          <p>
            Joining TryBuy.club as a local partner is simple. Sign up today, and our team will
            provide you with all the tools and support needed to get started. Together, we can bring
            exciting new investment opportunities to your region!
          </p>
        </div>

        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageSellerGuides_" />
        </div>
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default JoinUsAsPartners;
