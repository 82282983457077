import React, { useEffect, useState } from "react";
import ModalReserveMobile from "./ModalReserveMobile";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import getListingById from "actions/getListingById";
import { User } from "Types/User";
import getUser from "actions/getUser";
import getReservationsForListing from "actions/getReservationsForListing";
import { Reservation } from "../listing-stay-detail/StayDatesRangeInput";
import { Listing } from "Types/Listing";
import { checkTargetForNewValues } from "framer-motion";

const MobileFooterSticky = () => {
  const [searchParams] = useSearchParams();
  const [startDate] = useState<Date | null>(new Date(searchParams.get("checkIn") || Date.now()));
  const [endDate] = useState<Date | null>(new Date(searchParams.get("checkOut") || Date.now()));
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const adultGuestCount = searchParams.get("adultGuestCount");
  const childrenGuestCount = searchParams.get("childrenGuestCount");
  const infantGuestCount = searchParams.get("infantGuestCount");
  const [owner, setOwner] = useState<User | null>();
  const [listing, setListing] = useState<Listing>();
  const [reservations, setReservations] = useState<Reservation[]>([
    {
      id: 1,
      startDate: new Date(),
      endDate: new Date(),
      listingId: 1,
      renterId: 1,
      pricePerNight: 1,
    },
  ]);
  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const listingData = await getListingById(Number(id));
      //@ts-ignore
      setListing(
        listingData || {
          id: 0,
          ownerId: 0,
          name: "",
          category: "",
          pricePerNight: 0,
          listingCat: "",
          categorySlug: "",
          location: null,
          city: null,
          address: "",
          roomCount: 1,
          bathroomCount: 1,
          guestCount: 1,
          bedCount: 1,
          images: "",
          price: 1,
          propertyPrice: 1,
          priceNegotiable: false,
          description: "",
          size: "",
          locationCoords: [1, 1],
          lat: 1,
          lng: 1,
          galleryImgs: "",
          amenities: [],
          airbnb: "",
          booking: "",
          other: "",
          priceUnit: "",
          sizeUnit: "",
          packageId: "",
        }
      );
      const ownerData = await getUser(Number(listingData?.ownerId));
      setOwner(ownerData);
      const reservationsData = await getReservationsForListing(Number(listingData?.id));
      if (reservationsData !== null) {
        setReservations(reservationsData);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 600);
    };

    fetchData();
  }, []);
  const navigate = useNavigate();
  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const calculateNumberOfNights = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      const timeDifference = endDate.getTime() - startDate.getTime();
      const numberOfNights = Math.ceil(timeDifference / (1000 * 3600 * 24));
      return numberOfNights;
    }
    return 0;
  };
  return (
    <div className="notranslate block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
      <div className="container flex items-center justify-between">
        {isLoading ? (
          <div className="animate-pulse rounded-lg">
            <span className="block text-xl font-semibold invisible">
              {listing?.pricePerNight} €
              <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
                / per night
              </span>
            </span>
          </div>
        ) : (
          <div className="animate-appear">
            <span className="block text-xl font-semibold text-[#8c51bd]">
              {listing?.price} {listing?.priceUnit == "USD" ? "$" : "€"}
              <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
                / property
              </span>
            </span>
            <span className="block text-l font-semibold text-[#2563eb]">
              {listing?.pricePerNight} {listing?.priceUnit == "USD" ? "$" : "€"}
              <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
                / per night
              </span>
            </span>
          </div>
        )}
        {!isLoading ? (
          <ModalSelectDate
            reservations={reservations}
            //@ts-ignore
            listing={listing}
            renderChildren={({ openModal }) => (
              <span onClick={openModal} className="text-md underline font-bold flex flex-col">
                <span>Choose dates</span>
                <span>
                  {converSelectedDateToString([
                    new Date(formatDate(new Date(searchParams.get("checkIn") || Date.now()))),
                    new Date(formatDate(new Date(searchParams.get("checkOut") || Date.now()))),
                  ])}
                </span>
              </span>
            )}
          />
        ) : (
          ""
        )}
      </div>
      {/* <ModalReserveMobile
          // renderChildren={({ openModal }) =>
          //   isLoading ? (
          //     <ButtonPrimary
          //       sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
          //       onClick={() => {
          //         navigate(`/book-listing/${16}/?${window.location.href.split("?")[1]}`);
          //       }}
          //       className="disabled:cursor-not-allowed animate-pulse"
          //       disabled={
          //         calculateNumberOfNights(
          //           new Date(searchParams.get("checkIn") || Date.now()),
          //           new Date(searchParams.get("checkOut") || Date.now())
          //         ) < 1 ||
          //         Number(adultGuestCount) +
          //           Number(childrenGuestCount) +
          //           Number(infantGuestCount) ===
          //           0 ||
          //         isLoading
          //       }
          //     >
          //       <div className="invisible">Book Listing</div>
          //     </ButtonPrimary>
          //   ) : (
          //     <ButtonPrimary
          //       sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
          //       onClick={() => {
          //         navigate(`/book-listing/${listing?.id}/?${window.location.href.split("?")[1]}`);
          //       }}
          //       className="disabled:cursor-not-allowed animate-appear"
          //       disabled={
          //         calculateNumberOfNights(
          //           new Date(searchParams.get("checkIn") || Date.now()),
          //           new Date(searchParams.get("checkOut") || Date.now())
          //         ) < 1 ||
          //         Number(1) + Number(1) + Number(1) === 0 ||
          //         isLoading
          //       }
          //     >
          //       <div>Book listing</div>
          //     </ButtonPrimary>
          //   )
          // }
        /> */}
    </div>
  );
};

export default MobileFooterSticky;
