import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

export interface PageSellerGuidesProps {
  className?: string;
}

const PageSupport: FC<PageSellerGuidesProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageSellerGuides overflow-hidden relative ${className}`}
      data-nc-id="PageSellerGuides"
    >
      <Helmet>
        <title>Support - TryBuy.club</title> {/* Updated title */}
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div className="py-16 lg:py-28 space-y-8 lg:space-y-12">
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            Support - We're Here to Help! {/* Updated heading */}
          </h2>

          <p>
            At TryBuy.club, we value your experience and are committed to providing the best support
            possible. Whether you need assistance with your account, have questions about how our
            platform works, or need help with a transaction, our dedicated support team is here to
            help.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            How Can We Assist You?
          </h3>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Account Issues:</h4>
          <p>
            Having trouble logging in or managing your account? Our support team can help you
            resolve any account-related issues quickly and efficiently.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Platform Assistance:</h4>
          <p>
            If you need guidance on how to navigate TryBuy.club, we can walk you through the
            features, show you how to search for properties, make reservations, or express interest
            in a purchase.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Transaction Support:</h4>
          <p>
            Have questions about your transactions, payments, or property purchases? Our team can
            assist with any inquiries and ensure that everything is processed smoothly.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Security Concerns:</h4>
          <p>
            If you suspect any fraudulent activity or have questions about how to protect your
            account, we can help you understand how to secure your information and avoid scams.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Technical Support:</h4>
          <p>
            If you experience any technical issues while using the platform, our support team is
            here to help resolve the problem and ensure you have a seamless experience.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">Contact Us</h3>
          <p>
            Our team is available to assist you with any questions or concerns. To get in touch with
            us, please use the following contact methods:
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Email Support:</h4>
          <p>Send us an email at contacts@trybuy.club. We aim to respond within 24 hours.</p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">WhatsApp Support:</h4>
          <p>
            For immediate assistance, you can reach us via WhatsApp at +12093427780 or
            +359885368594.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            FAQ - Frequently Asked Questions
          </h3>
          <p>
            For quick answers to common questions, check out our FAQ section. Here you’ll find
            answers about how to use the platform, manage your account, and handle transactions.
          </p>

          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            We’re Here to Ensure Your Success
          </h2>
          <p>
            At TryBuy.club, our goal is to make your experience as smooth and easy as possible. If
            you ever need assistance, don't hesitate to reach out—we’re here to support you every
            step of the way.
          </p>
        </div>

        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageSellerGuides_" />
        </div>

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageSupport;
