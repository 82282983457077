import React, { useEffect, useState } from "react";

const GTranslate: React.FC = () => {
  const [scriptError, setScriptError] = useState(false);

  useEffect(() => {
    //@ts-ignore
    window.gtranslateSettings = {
      default_language: "en", // Default language
      detect_browser_language: true,
      languages: ["fr", "en", "it", "es", "tr", "ru", "de", "bg", "pl"], // Supported languages
      globe_color: "#66aaff",
      wrapper_selector: ".gtranslate_wrapper",
      horizontal_position: "left",
      vertical_position: "bottom",
    };

    // Add the GTranslate script dynamically
    const script = document.createElement("script");
    script.src = "https://cdn.gtranslate.net/widgets/latest/globe.js";
    script.defer = true;
    document.body.appendChild(script);

    // Polling method to check the selected language
    const pollForLanguageChange = () => {
      const currentLang = localStorage.getItem("selectedLanguage");
      const langElement = document.querySelector("html");

      if (langElement && langElement.getAttribute("lang") !== currentLang) {
        const newLang = langElement.getAttribute("lang");
        if (newLang) {
          localStorage.setItem("selectedLanguage", newLang); // Store the selected language
          console.log("Language changed, new language: ", newLang);
        }
      }
    };

    // Poll for language change every 500ms
    const intervalId = setInterval(pollForLanguageChange, 500);

    // Cleanup polling when the component is unmounted
    return () => {
      clearInterval(intervalId);
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="flex justify-center items-center mt-6 w-full">
      <div className="gtranslate_wrapper" style={{ textAlign: "center" }}></div>
    </div>
  );
};

export default GTranslate;
