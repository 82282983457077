import React, { useCallback, useState } from "react";
import { useForm, SubmitHandler, SubmitErrorHandler } from "react-hook-form";
import Modal from "./Modal";
import axios from "axios";
import useRegisterModal from "../../hooks/useRegisterModal";
import Heading from "../Heading";
import toast from "react-hot-toast";
import useLoginModal from "../../hooks/useLoginModal";
import Input from "components/Inputs/Input";
import Button from "shared/Button/Button";
import useChooseRegisterTypeModal from "hooks/useChooseRegisterTypeModal";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import useRegisterModalBusiness from "hooks/useRegisterModalBusiness";

const ChooseRegisterType: React.FC = () => {
  const regType = useChooseRegisterTypeModal();
  const registerModal = useRegisterModal();
  const registerModalBusiness = useRegisterModalBusiness();
  const [isLoading, setIsLoading] = useState(false);
  const bodyContent = (
    <div className="flex flex-col gap-4">
      <Heading title="Welcome!" subtitle="Choose Account Type!" />

      <div className="flex flex-col gap-2">
        <p className="text-sm text-gray-600 pl-4">
          Perfect for personal use. Browse and interact but cannot list properties.
        </p>
        <ButtonSecondary
          onClick={() => {
            registerModal.onOpen();
          }}
          className="py-3 px-6 text-lg bg-blue-600 text-black rounded-md hover:bg-blue-700"
        >
          Individual
        </ButtonSecondary>
      </div>

      <div className="flex flex-col gap-2">
        <p className="text-sm text-gray-600 pl-4">
          Ideal for Hotels. List and manage your properties with ease.
        </p>
        <ButtonSecondary
          onClick={() => {
            registerModalBusiness.onOpen();
          }}
          className="py-3 px-6 text-lg bg-green-500 text-black rounded-md hover:bg-green-600"
        >
          Hotel (Business)
        </ButtonSecondary>
      </div>
    </div>
  );

  const footerContent = (
    <div className="flex flex-col gap-4 mt-3 ">
      <hr />
    </div>
  );

  return (
    <Modal
      disabled={isLoading}
      isOpen={regType.isOpen}
      title="Choose Account Type!"
      onClose={regType.onClose}
      body={bodyContent}
      footer={footerContent}
    />
  );
};

export default ChooseRegisterType;
