import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

export interface PageSellerGuidesProps {
  className?: string;
}

const AdvertiseOnTryBuy: FC<PageSellerGuidesProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageSellerGuides overflow-hidden relative ${className}`}
      data-nc-id="PageSellerGuides"
    >
      <Helmet>
        <title>Advertise on TryBuy.club</title>
      </Helmet>
      <BgGlassmorphism />
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div className="py-16 lg:py-28 space-y-8 lg:space-y-12">
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            Advertise on TryBuy.club
          </h2>
          <p>
            Welcome to TryBuy.club, the exclusive platform dedicated to hotel properties. If you're
            a hotel owner looking to sell or showcase hotel rooms and investment properties within
            your hotel, our platform offers you the ideal space to reach a targeted audience of
            investors actively seeking hotel investment opportunities.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            Why Advertise on TryBuy.club?
          </h3>
          <ul className="list-disc pl-6 space-y-4">
            <li>
              <strong>Exclusive Focus on Hotels:</strong> TryBuy.club is specifically designed for
              hotels and hotel investment properties. This means your property will only be seen by
              investors and buyers looking to invest in hotels, ensuring a highly relevant audience
              for your listings.
            </li>
            <li>
              <strong>Global Reach:</strong> Our platform offers global exposure, connecting your
              hotel with potential investors from around the world.
            </li>
            <li>
              <strong>Easy and Effective:</strong> Listing your hotel on TryBuy.club is
              straightforward and efficient, helping you connect with the right buyers without
              unnecessary hassle.
            </li>
          </ul>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">How It Works</h3>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">1. Registration Fee</h4>
          <p>
            To start listing your hotel, you’ll need to pay a one-time registration fee of $100.
            This fee includes:
          </p>
          <ul className="list-disc pl-6 space-y-4">
            <li>
              <strong>Hotel Profile Representation:</strong> Your hotel will have a dedicated
              profile on our platform, displaying all the essential details for investors to
              discover.
            </li>
            <li>
              <strong>12 Free Listings:</strong> The registration fee includes up to 12 free
              property listings within your hotel. These can be individual rooms or other investment
              opportunities within your hotel.
            </li>
          </ul>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">
            2. Additional Listings
          </h4>
          <p>
            Once the 12 free listings have been used, you can purchase additional listing packages.
            These packages allow you to continue showcasing more properties or hotel rooms for sale.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">3. Featured Listings</h4>
          <p>
            Want to give your listings a boost? TryBuy.club allows you to promote your listings by
            upgrading them to Featured Listings. This positions your property at the top of the
            search results, increasing visibility and attracting more attention from potential
            buyers.
          </p>
          <ul className="list-disc pl-6 space-y-4">
            <li>
              <strong>Premium Placement:</strong> Featured Listings are placed at the top of the
              page, giving your property maximum exposure.
            </li>
            <li>
              <strong>Increased Engagement:</strong> Featured Listings are more likely to receive
              inquiries, leading to quicker sales.
            </li>
          </ul>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">How to Get Started</h3>
          <ul className="list-disc pl-6 space-y-4">
            <li>
              <strong>Register Your Hotel:</strong> Pay the $100 registration fee to create your
              hotel profile and start listing your properties.
            </li>
            <li>
              <strong>List Your Hotel Properties:</strong> Upload up to 12 listings for free. Be
              sure to include high-quality images, descriptions, and all relevant details about each
              property.
            </li>
            <li>
              <strong>Purchase Additional Listings:</strong> After your free listings are used, you
              can purchase additional packages to continue showcasing more properties.
            </li>
            <li>
              <strong>Promote Your Listings:</strong> Enhance your property’s visibility by opting
              for Featured Listings to ensure maximum exposure on the platform.
            </li>
          </ul>

          <p>Sell Your Hotel Properties Faster</p>
          <p>
            TryBuy.club is the dedicated platform for hotel properties, allowing you to reach the
            right buyers and investors. By listing your hotel on TryBuy.club, you open the door to a
            global audience of potential buyers looking for investment opportunities in hotels.
          </p>
          <p>Start advertising today and get your hotel properties seen by the right investors!</p>
        </div>
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageSellerGuides_" />
        </div>
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default AdvertiseOnTryBuy;
