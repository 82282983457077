import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

export interface PageSellerGuidesProps {
  className?: string;
}

const BuyerGuides: FC<PageSellerGuidesProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageSellerGuides overflow-hidden relative ${className}`}
      data-nc-id="PageSellerGuides"
    >
      <Helmet>
        <title>Buyer Guide - TryBuy.club</title>
      </Helmet>

      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div className="py-16 lg:py-28 space-y-8 lg:space-y-12">
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">Buyer Guide</h2>

          <p>
            Welcome to TryBuy.club, the premier platform for discovering investment opportunities in
            hotel properties worldwide. Whether you’re an experienced investor or a first-time
            buyer, our platform offers a simple, secure, and efficient way to find and purchase the
            perfect hotel property.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            What You Can Expect to Find:
          </h3>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">
            Exclusive Hotel Properties:
          </h4>
          <p>
            Browse a curated selection of high-quality hotel properties available for investment.
            Our listings include hotels in prime locations around the world, ranging from luxury
            resorts to charming boutique hotels, with high potential for growth and return on
            investment.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">
            Investment-Ready Properties:
          </h4>
          <p>
            Every listing on TryBuy.club is carefully reviewed to ensure it meets our standards of
            quality and profitability. We focus on properties with proven performance and great
            potential for future success, allowing you to make informed and confident decisions.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">
            Wide Range of Locations:
          </h4>
          <p>
            Whether you're looking for properties in busy city centers, scenic beach resorts, or
            emerging markets, our platform offers a variety of options. You can find opportunities
            in top destinations across the globe, helping you diversify your investment portfolio.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">How It Works:</h3>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">
            Create a Free Account:
          </h4>
          <p>
            To gain access to TryBuy.club and start browsing properties, simply create a free
            account. This will allow you to view detailed listings and access exclusive investment
            opportunities.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Browse and Search:</h4>
          <p>
            Once registered, start browsing through our diverse listings of hotel properties. Use
            filters to narrow your search based on location, price range, and your specific
            investment criteria. With just a few clicks, you can find properties that match your
            preferences.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Detailed Listings:</h4>
          <p>
            Each property listing includes comprehensive details about the hotel, investment
            potential, and expected return on investment. You can view high-quality photos,
            financial reports, and other key information that helps you assess the property’s value.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Reserve a Stay:</h4>
          <p>
            We understand that purchasing a hotel property is a big decision. That’s why we offer
            the option to reserve a stay at the property you're interested in before making your
            purchase. This allows you to experience the property firsthand, evaluate its quality,
            and ensure it meets your investment goals.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Express Interest:</h4>
          <p>
            When you’ve found a property you’re interested in, you can submit a purchase request
            directly through the platform. Our easy-to-use system makes it simple to express your
            interest and start the process of buying the property. We facilitate the communication
            between you and the hotel seller to ensure a smooth transaction.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">
            Secure Communication and Transactions:
          </h4>
          <p>
            All reservations and purchase requests are processed through TryBuy.club, ensuring
            transparency and security. While payments for the property are handled directly between
            you and the seller, the platform ensures a seamless process for expressing interest and
            reserving your stay.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Expert Support:</h4>
          <p>
            Our team is here to assist you every step of the way. Whether you need help with
            selecting properties, making a reservation, or understanding the buying process, we
            offer expert guidance to ensure you have the best experience possible.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            Why Buy Through TryBuy.club?
          </h3>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">
            Easy Access to a Global Market:
          </h4>
          <p>
            Our platform connects you to a wide range of properties from around the world, providing
            a unique opportunity to diversify your investment portfolio.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">
            Transparency and Security:
          </h4>
          <p>
            We prioritize transparency in all transactions, offering a safe and reliable environment
            for buying hotel properties.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">
            Test Before You Invest:
          </h4>
          <p>
            The ability to reserve a stay and experience the property before committing to purchase
            adds an extra layer of confidence for investors.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Global Support:</h4>
          <p>
            Our expert team is ready to assist you with your investment decisions, ensuring a smooth
            and successful process from start to finish.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            Start Your Investment Journey Today
          </h3>
          <p>
            Ready to explore investment opportunities? Create your free account now, and start
            discovering the right hotel properties for your portfolio on TryBuy.club. Whether you’re
            looking to invest in your first hotel property or expand your existing portfolio, we
            make it easy to find, reserve, and purchase the perfect property.
          </p>
        </div>

        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageSellerGuides_" />
        </div>

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default BuyerGuides;
