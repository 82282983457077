import React, { useCallback, useRef, useState } from "react";
import { useForm, SubmitHandler, SubmitErrorHandler } from "react-hook-form";
import Modal from "./Modal";
import axios from "axios";
import useRegisterModal from "../../hooks/useRegisterModal";
import Heading from "../Heading";
import toast from "react-hot-toast";
import useLoginModal from "../../hooks/useLoginModal";
import Input from "components/Inputs/Input";
import Button from "shared/Button/Button";
import useRegisterModalBusiness from "hooks/useRegisterModalBusiness";

interface FormData {
  companyname: string;
  email: string;
  password: string;
  tel: string;
  businessRegNmb: string;
}

const RegisterModalBusiness: React.FC = () => {
  const registerModalBusiness = useRegisterModalBusiness();
  const loginModal = useLoginModal();
  const [isLoading, setIsLoading] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [hasAgreed, setHasAgreed] = useState(false);
  const [hasRead, setHasRead] = useState(false);
  const termsRef = useRef<HTMLDivElement>(null);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      companyname: "",
      email: "",
      password: "",
      businessRegNmb: "",
      tel: "",
    },
  });
  const password = watch("password");
  const email = watch("email");

  const handleScroll = () => {
    if (termsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = termsRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setHasRead(true); // Enable the agree button
      }
    }
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    if (!hasAgreed) {
      toast.error("You must read and agree to the Buyer Agreement to register.");
      return;
    }
    if (password.length >= 6 && email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      setIsLoading(true);

      axios
        .post("/auth/register", data, {
          headers: { Authorization: localStorage.getItem("token") },
        })
        .then((data) => {
          registerModalBusiness.onClose();
          toast.success("Account registered successfully");
          loginModal.onOpen();
          setIsLoading(false);
          reset();
        })
        .catch((error) => {
          if (Number(error.status) === 401) {
            toast.error("Invalid email address");
          } else {
            toast.error("Something went wrong");
          }
          setIsLoading(false);
        });
    } else {
      toast.error(
        "Password must be at least 8 characters long and the email address must be valid!"
      );
    }
  };

  const bodyContent = (
    <div className="flex flex-col gap-4">
      <Heading title="Welcome!" subtitle="Create a Hotel (Business) account!" />
      <Input
        id="email"
        label="Email Address"
        type="email"
        disabled={isLoading}
        errors={errors}
        register={register}
        required
      />
      <Input
        id="companyname"
        label="Company Name"
        type="text"
        disabled={isLoading}
        errors={errors}
        required
        register={register}
      />
      <Input
        id="password"
        label="Password"
        type="password"
        disabled={isLoading}
        errors={errors}
        register={register}
        required
      />
      <Input
        id="tel"
        label="Phone Number"
        type="tel"
        disabled={isLoading}
        errors={errors}
        register={register}
        required
      />
      <Input
        id="businessRegNmb"
        label="Business Registration Number"
        type="text"
        disabled={isLoading}
        errors={errors}
        register={register}
        required
      />
      <div className="flex flex-col gap-2">
        <button
          type="button"
          onClick={() => setShowTerms(!showTerms)}
          className="text-sm text-blue-500 hover:underline"
        >
          {showTerms ? "Hide Partnership Agreement" : "Read Partnership Agreement"}
        </button>
        {showTerms && (
          <div
            ref={termsRef}
            onScroll={handleScroll}
            className="p-4 bg-gray-100 rounded-lg text-sm max-h-40 overflow-y-auto space-y-4"
          >
            <h2 className="text-xl font-semibold text-gray-800">
              Partnership Agreement between TryBuy.club and Hotel
            </h2>
            <p className="text-gray-600">
              This Partnership Agreement (the “Agreement”) is entered into force as of the date of
              account creation on the TryBuy.club platform (the “Effective Date”) by and between:
            </p>

            <ol className="list-decimal pl-5 space-y-2">
              <li>
                TryBuy.club, an online platform connecting property buyers and hotels offering
                investment opportunities in hotel properties (the “Platform”), and
              </li>
              <li>The Hotel, the entity creating an account to use the Platform (the “Hotel”).</li>
            </ol>

            <p className="text-gray-600">
              By registering an account on TryBuy.club, you acknowledge that you have read,
              understood, and agree to be bound by the terms and conditions of this Agreement.
            </p>

            <h3 className="text-lg font-semibold text-gray-800">1. Purpose of the Agreement</h3>
            <p className="text-gray-600">
              The purpose of this Agreement is to establish the terms under which the Hotel will
              list and promote its properties on TryBuy.club’s platform, enabling potential clients
              to rent, try, and purchase the listed properties.
            </p>

            <h3 className="text-lg font-semibold text-gray-800">
              2. Services Provided by TryBuy.club
            </h3>
            <ul className="list-disc pl-5 space-y-2 text-gray-600">
              <li>
                One-Time Registration Fee ($100): Includes a dedicated Hotel profile page for
                showcasing information.
              </li>
              <li>Allows the Hotel to list up to 12 properties on the platform free of charge.</li>
              <li>
                Hotels wishing to list more than 12 properties can purchase additional listing
                packages at rates determined by TryBuy.club.
              </li>
              <li>
                The Hotel agrees to pay a 1% commission on successful property sales initiated
                through the platform, including sales of properties added through additional listing
                packages.
              </li>
            </ul>

            <h3 className="text-lg font-semibold text-gray-800">3. Obligations of the Hotel</h3>
            <ul className="list-disc pl-5 space-y-2 text-gray-600">
              <li>
                Provide accurate and up-to-date property information, including descriptions,
                pricing, and availability.
              </li>
              <li>Maintain high-quality customer service for clients referred by TryBuy.club.</li>
              <li>
                Pay a 1% commission on successful property sales initiated through TryBuy.club.
              </li>
              <li>
                Comply with TryBuy.club’s policies and guidelines as updated from time to time.
              </li>
            </ul>

            <h3 className="text-lg font-semibold text-gray-800">4. Payment Terms</h3>
            <ul className="list-disc pl-5 space-y-2 text-gray-600">
              <li>
                The one-time registration fee of $100 must be paid in full prior to activation of
                the Hotel’s account and services on the platform.
              </li>
              <li>
                Payments for registration, additional listings, and Featured Listings must be made
                immediately through the platform. All transactions are processed securely.
              </li>
              <li>
                If a sale initiated through the platform is completed, hotel owners will pay a
                commission of 1% of the final sale price to TryBuy.club. This commission is due
                within 15 days after the transaction is completed, and the hotel owner will receive
                an invoice for the commission. Payment of the commission will be made via bank
                transfer.
              </li>
              <li>Fees are non-refundable.</li>
            </ul>

            <h3 className="text-lg font-semibold text-gray-800">5. Term and Termination</h3>
            <ul className="list-disc pl-5 space-y-2 text-gray-600">
              <li>
                This Agreement is valid for an indefinite period, subject to compliance with its
                terms.
              </li>
              <li>Either party may terminate the Agreement with 30 days' written notice.</li>
              <li>
                TryBuy.club reserves the right to suspend or terminate the Hotel’s account in cases
                of non-compliance with this Agreement.
              </li>
            </ul>

            <h3 className="text-lg font-semibold text-gray-800">6. Liability and Disclaimer</h3>
            <p className="text-gray-600">
              TryBuy.club shall not be held liable for disputes or issues arising between the Hotel
              and clients using the platform.
            </p>
            <p className="text-gray-600">
              The Hotel accepts full responsibility for the accuracy of its property listings and
              the quality of its services.
            </p>

            <h3 className="text-lg font-semibold text-gray-800">
              7. Confidentiality and Data Protection
            </h3>
            <p className="text-gray-600">
              Both parties agree to handle confidential information, including client data, in
              compliance with applicable privacy laws (e.g., GDPR).
            </p>
            <p className="text-gray-600">
              TryBuy.club will not share the Hotel’s data with third parties without prior consent.
            </p>

            <h3 className="text-lg font-semibold text-gray-800">8. Miscellaneous</h3>
            <ul className="list-disc pl-5 space-y-2 text-gray-600">
              <li>
                This Agreement represents the entire understanding between the parties and
                supersedes any prior agreements.
              </li>
              <li>
                Any amendments to this Agreement must be made in writing and signed by both parties.
              </li>
              <li>This Agreement shall be governed by the laws of the European Union.</li>
            </ul>

            <h3 className="text-lg font-semibold text-gray-800">9. Contact Information</h3>
            <p className="text-gray-600">
              For any questions or concerns regarding this Agreement, please contact us:
            </p>
            <p className="text-gray-600">
              Email:{" "}
              <a href="mailto:support@trybuy.club" className="text-blue-600">
                support@trybuy.club
              </a>
            </p>
            <p className="text-gray-600">WhatsApp: +12093427780; +359885368594</p>
          </div>
        )}
        {showTerms && (
          <button
            type="button"
            onClick={() => {
              setHasAgreed(true);
              setTimeout(() => {
                setShowTerms(false);
              }, 350);
            }}
            disabled={!hasRead}
            className={`mt-2 p-2 text-sm ${
              hasRead ? "bg-green-500 text-white" : "bg-gray-300 text-gray-500"
            } rounded`}
          >
            {hasAgreed ? "Agreed" : "I Agree to the Partnership Agreement"}
          </button>
        )}
      </div>
    </div>
  );

  const footerContent = (
    <div className="flex flex-col gap-4 mt-3 ">
      <hr />
      <div className="text-neutral-500 text-center mt-4 font-light">
        <div className="flex justify-center flex-row items-center gap-2">
          <div>Already have an account?</div>
          <div
            onClick={() => {
              registerModalBusiness.onClose();
              loginModal.onOpen();
            }}
            className="text-neutral-800 cursor-pointer hover:underline"
          >
            Log in
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      disabled={isLoading}
      isOpen={registerModalBusiness.isOpen}
      title="Registration"
      actionLabel="Register"
      onClose={registerModalBusiness.onClose}
      onSubmit={handleSubmit(onSubmit)}
      body={bodyContent}
      footer={footerContent}
    />
  );
};

export default RegisterModalBusiness;
