import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
//import BackgroundSection from "components/BackgroundSection/BackgroundSection"; // If you still need this
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2"; // If you still need this

export interface PrivacyPolicyProps {
  className?: string;
}

const PrivacyPolicy: FC<PrivacyPolicyProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PrivacyPolicy overflow-hidden relative ${className}`}
      data-nc-id="PrivacyPolicy" // Update the data-nc-id
    >
      <Helmet>
        <title>Privacy Policy - TryBuy.club</title>
      </Helmet>
      <BgGlassmorphism />
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div className="py-16 lg:py-28 space-y-8 lg:space-y-12">
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">Privacy Policy</h2>
          <p>Effective Date: 1/28/2025</p>
          <p>
            At TryBuy.club, we value your privacy and are committed to protecting your personal
            information. This Privacy Policy explains how we collect, use, disclose, and safeguard
            your information when you use our platform. Please read this policy carefully to
            understand our views and practices regarding your data and how we treat it.
          </p>

          <h3>1. Information We Collect</h3>
          <p>We may collect the following types of information when you use our platform:</p>
          <h4>1.1. Personal Information:</h4>
          <ul>
            <li>
              Name, email address, phone number, and other contact details when you create an
              account.
            </li>
            <li>
              Payment details for transactions related to registration, listings, and Featured
              Listings.
            </li>
          </ul>
          <h4>1.2. Property Information:</h4>
          <ul>
            <li>Details about your hotel or properties when creating a listing.</li>
          </ul>
          <h4>1.3. Usage Information:</h4>
          <ul>
            <li>
              Information about how you interact with the platform, such as pages viewed, time spent
              on the platform, and navigation patterns.
            </li>
          </ul>
          <h4>1.4. Communication Data:</h4>
          <ul>
            <li>
              Records of communication, including inquiries, messages, or feedback sent through the
              platform or via email.
            </li>
          </ul>
          <h4>1.5. Technical Data:</h4>
          <ul>
            <li>
              IP address, browser type, operating system, and other device-related information.
            </li>
          </ul>

          <h3>2. How We Use Your Information</h3>
          <p>We use the information collected for the following purposes:</p>
          <ul>
            <li>
              <b>Account Management:</b> To create and manage your account.
            </li>
            <li>
              <b>Service Delivery:</b> To process listings, payments, and facilitate transactions
              between buyers and sellers.
            </li>
            <li>
              <b>Communication:</b> To respond to your inquiries, provide updates, and send
              important notifications about your account or transactions.
            </li>
            <li>
              <b>Marketing and Advertising:</b> To send promotional offers, newsletters, and
              advertisements, where applicable.
            </li>
            <li>
              <b>Platform Improvement:</b> To analyze usage trends and improve the functionality and
              user experience of TryBuy.club.
            </li>
            <li>
              <b>Legal Compliance:</b> To comply with applicable laws, regulations, and contractual
              obligations.
            </li>
          </ul>

          <h3>3. Sharing Your Information</h3>
          <p>We may share your information with third parties under the following circumstances:</p>
          <h4>3.1. Service Providers:</h4>
          <p>
            To payment processors, hosting providers, and other vendors who help us operate the
            platform.
          </p>
          <h4>3.2. Business Transactions:</h4>
          <p>
            In case of a merger, acquisition, or sale of all or part of our assets, your information
            may be transferred.
          </p>
          <h4>3.3. Legal Requirements:</h4>
          <p>
            When required by law or to protect the rights, property, or safety of TryBuy.club, our
            users, or others.
          </p>

          <h3>4. Data Security</h3>
          <p>
            We implement robust security measures to protect your information against unauthorized
            access, disclosure, alteration, or destruction. However, no method of transmission or
            electronic storage is completely secure, and we cannot guarantee absolute security.
          </p>

          <h3>5. Your Rights</h3>
          <p>You have the following rights regarding your personal data:</p>
          <ul>
            <li>
              <b>Access and Correction:</b> You may request access to or correction of the personal
              information we hold about you.
            </li>
            <li>
              <b>Data Deletion:</b> You may request that we delete your personal information,
              subject to legal and contractual obligations.
            </li>
            <li>
              <b>Opt-Out:</b> You may opt out of receiving marketing communications by following the
              unsubscribe instructions provided in the emails or contacting us directly.
            </li>
          </ul>

          <h3>6. Cookies and Tracking Technologies</h3>
          <p>
            We use cookies and similar technologies to enhance your experience on the platform.
            These cookies may track:
          </p>
          <ul>
            <li>Your browsing preferences and settings.</li>
            <li>Usage patterns to improve platform functionality.</li>
          </ul>
          <p>
            You can manage or disable cookies in your browser settings, though this may affect your
            platform experience.
          </p>

          <h3>7. Third-Party Links</h3>
          <p>
            Our platform may contain links to third-party websites, such as property booking or
            advertising platforms. We are not responsible for the privacy practices of these
            external sites and encourage you to read their privacy policies.
          </p>

          <h3>8. Data Retention</h3>
          <p>
            We retain your personal information as long as it is necessary to fulfill the purposes
            outlined in this Privacy Policy or comply with legal obligations.
          </p>

          <h3>9. International Data Transfers</h3>
          <p>
            If you are accessing the platform from outside the country where our servers are
            located, your information may be transferred to and processed in a country with
            different data protection laws. By using the platform, you consent to this transfer.
          </p>

          <h3>10. Updates to This Privacy Policy</h3>
          <p>
            We reserve the right to update or modify this Privacy Policy at any time. If we make
            material changes, we will notify you via email or on the platform. Your continued use of
            TryBuy.club after such changes signifies your acceptance of the revised policy.
          </p>

          <h3>11. Contact Us</h3>
          <p>
            If you have any questions or concerns about this Privacy Policy or how we handle your
            information, please contact us:
          </p>
          <p>Email: contacts@trybuy.club</p>
          <p>WhatsApp: +12093427780; +359885368594</p>
        </div>
      </div>
      <SectionSubscribe2 />
    </div>
  );
};

export default PrivacyPolicy; // Export the PrivacyPolicy component
