import React, { FC, useState } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/newsletter-img.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";
import axios, { AxiosResponse } from "axios";
import toast from "react-hot-toast";

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const res: AxiosResponse = await axios.post(
        "/users/subscribe",
        { email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token") || "",
          },
        }
      );

      toast.promise(
        Promise.resolve(res),
        {
          loading: "Subscribing...",
          success: <b>Email successfully subscribed</b>,
          error: <b>Eamil already subscribed.</b>,
        },
        { duration: 5000 }
      );
    } catch (error) {
      toast.error("Email already subscribed");
    } finally {
      setEmail("");
      setIsLoading(false);
    }
  };
  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">Subscribe to our newsletter for updates! 🎉</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Explore fresh perspectives on a wide range of topics and join our inclusive community.
        </span>

        <form className="mt-10 relative max-w-sm">
          <Input
            required
            value={email}
            aria-required
            placeholder="Email"
            type="email"
            disabled={isLoading}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            rounded="rounded-full"
          />
          <ButtonCircle
            type="submit"
            disabled={isLoading}
            className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
            onClick={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
        </form>
      </div>
      <div className="w-80 h-full">
        <div className="flex-grow">{<NcImage width={100} height={100} src={rightImg} />}</div>
      </div>
    </div>
  );
};

export default SectionSubscribe2;
