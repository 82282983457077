import React, { FC, useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { UserContext } from "UserContext";
import ModalButton from "components/ModalButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Interface for plan items
interface PlanItem {
  id: string;
  name: string;
  badgeColor: string;
  data: { id: number; days: number; price: number }[];
  perks: string[];
}

// Plan items data (Standard and Premium)
const planItems: PlanItem[] = [
  {
    id: "premium",
    name: "Premium",
    badgeColor: "gold",
    data: [
      {
        id: 1,
        days: 1,
        price: 100,
      },
    ],
    perks: ["Hotel Presentation", "More visibility", "Better listing advertisment", "25 Listings"],
  },
];

const PageAccountPayment: FC = () => {
  const { user } = useContext(UserContext);
  const [selectedPlan, setSelectedPlan] = useState<PlanItem | null>(null); // Track selected plan
  const [isApproved, setIsApproved] = useState<number>(0);
  const navigate = useNavigate();

  // Set approval state and redirect if approved
  useEffect(() => {}, [user, isApproved]);

  // Handle Plan Selection
  const handleSelectPlan = (plan: PlanItem) => {
    setSelectedPlan(plan); // Set selected plan
  };

  // Handle payment
  const handlePayment = async () => {
    try {
      if (!selectedPlan) return;
      axios
        .post(
          "/plans/pay",
          {
            userId: user?.id,
          },
          { headers: { Authorization: localStorage.getItem("token") } }
        )
        .then((res) => {
          toast.loading("Redirecting to payment");
          if (res.data.url) {
            window.location.href = res.data.url;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      toast.error("Payment failed. Please try again.");
    }
  };

  return (
    <div className="nc-PageAbout overflow-hidden relative" data-nc-id="PageAbout">
      <Helmet>
        <title>Check Account - TryBuy.club</title>
      </Helmet>

      <div className="flex flex-col items-center justify-center gap-6 mt-10 min-h-screen">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-10">
          Choose Your Payment Plan
        </h2>

        {/* Outer container for both plans */}
        <div className="flex justify-center items-center w-full py-10">
          <div className="flex flex-col justify-center items-center gap-8">
            {/* Plan Boxes */}
            <div className="flex justify-center gap-8">
              <div
                className={`flex flex-col cursor-pointer items-center justify-between p-24 w-100 h-100 border-4 rounded-lg shadow-xl bg-white transition-all ${
                  selectedPlan?.id === "premium" ? "border-yellow-500" : "border-gray-300"
                }`}
                onClick={() => handleSelectPlan(planItems[0])}
              >
                <div className={`bg-yellow-500 text-white p-4 rounded-xl text-xl`}>
                  {planItems[0].name}
                </div>
                <span className="text-xl mt-2 text-gray-700">
                  {planItems[0].data[0].price.toFixed(2)} € / Year
                </span>
                <ul className="mt-4 text-xl text-gray-600">
                  {planItems[0].perks.map((perk, index) => (
                    <li key={index} className="flex items-center">
                      <span className="text-xl">✔️ {perk}</span>
                    </li>
                  ))}
                </ul>
                <div className={`flex justify-center w-full mt-6 `}>
                  <ModalButton
                    label={`Pay ${planItems[0].data[0].price.toFixed(2)} €`}
                    onClick={handlePayment}
                    disabled={!selectedPlan}
                  />
                </div>
              </div>
            </div>

            {/* Payment Button */}
          </div>
        </div>

        {/* Payment Button centered at the bottom */}
      </div>
    </div>
  );
};

export default PageAccountPayment;
