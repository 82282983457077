import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

export interface PageSellerGuidesProps {
  className?: string;
}

const SecurityTips: FC<PageSellerGuidesProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageSellerGuides overflow-hidden relative ${className}`}
      data-nc-id="PageSellerGuides"
    >
      <Helmet>
        <title>Security Tips - TryBuy.club</title>
      </Helmet>

      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div className="py-16 lg:py-28 space-y-8 lg:space-y-12">
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            Security Tips to Protect Yourself from Scams and Fraud
          </h2>

          <p>
            At TryBuy.club, your security is our top priority. To ensure that you have a safe and
            secure experience when using our platform, here are some essential tips to protect
            yourself from phishing scams, fraudulent emails, and other online threats.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            1. Be Cautious with Unsolicited Emails
          </h3>
          <p>
            Always be cautious when receiving unsolicited emails, especially those that ask for
            personal or financial information. TryBuy.club will never ask for sensitive details such
            as passwords, credit card numbers, or social security numbers via email. If you receive
            an unexpected email claiming to be from TryBuy.club or any other trusted company,
            double-check the sender’s email address. Phishing emails often come from addresses that
            look similar to, but are not exactly the same as, official addresses.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            2. Verify Links Before Clicking
          </h3>
          <p>
            Never click on links in emails or text messages unless you are sure they come from a
            trusted source. Hover over the link with your cursor to see the destination URL and
            ensure it matches the legitimate website’s address. When in doubt, type the website’s
            address directly into your browser instead of clicking on a link.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            3. Use Strong, Unique Passwords
          </h3>
          <p>
            Protect your TryBuy.club account with a strong, unique password that is difficult to
            guess. Avoid using common passwords like “123456” or “password.” Consider using a
            password manager to generate and store secure passwords for each of your accounts.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            4. Be Careful When Sharing Personal Information
          </h3>
          <p>
            Avoid sharing sensitive personal information over email, especially with individuals or
            companies that you do not know well. Always verify the identity of the person you are
            communicating with before disclosing any personal details. If you’re unsure about the
            legitimacy of a request, contact the company directly through an official contact method
            to verify the inquiry.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            5. Look for Red Flags in Emails and Websites
          </h3>
          <p>
            Phishing emails often contain spelling or grammar errors, suspicious attachments, or
            requests for urgent action. Always take a moment to review the content for any
            inconsistencies. On websites, check for HTTPS in the URL (look for the padlock icon in
            your browser’s address bar) to ensure the site is secure before entering sensitive
            information.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            6. Report Suspicious Activity
          </h3>
          <p>
            If you receive a suspicious email or notice any unusual activity on your TryBuy.club
            account, report it immediately to our support team. We take all reports of fraud and
            phishing seriously and will take appropriate action. If you encounter a suspicious
            website or suspect fraudulent activity, don’t hesitate to contact the relevant
            authorities, such as your bank or local consumer protection agency.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            7. Keep Your Software Up to Date
          </h3>
          <p>
            Ensure that your web browser, operating system, and antivirus software are up to date
            with the latest security patches. This helps protect your device from known
            vulnerabilities and security threats.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            8. Trust Your Instincts
          </h3>
          <p>
            If something seems too good to be true or feels suspicious, trust your instincts and
            proceed with caution. Fraudsters often use high-pressure tactics to rush you into making
            decisions without thinking them through. Always take the time to verify any claims or
            offers before committing.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            9. Use Secure Payment Methods
          </h3>
          <p>
            When making a purchase or investment on TryBuy.club, always use a secure payment method
            (such as credit card or PayPal) that offers fraud protection. Avoid wire transfers or
            payments made via untraceable methods.
          </p>

          <p>
            By following these simple tips, you can help protect yourself from scams and ensure a
            safe experience while using TryBuy.club and browsing online. Stay vigilant and always
            prioritize security when managing your personal information.
          </p>
        </div>

        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageSellerGuides_" />
        </div>

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default SecurityTips;
