import create from "zustand";

interface ChooseRegisterTypeModal {
  isOpen: boolean;
}

interface ChooseRegisterTypeActions {
  onOpen: () => void;
  onClose: () => void;
}

const useChooseRegisterTypeModal = create<ChooseRegisterTypeModal & ChooseRegisterTypeActions>(
  (set) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
  })
);

export default useChooseRegisterTypeModal;
