import React, { useState, useRef } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import Modal from "./Modal";
import axios from "axios";
import useRegisterModal from "../../hooks/useRegisterModal";
import Heading from "../Heading";
import toast from "react-hot-toast";
import useLoginModal from "../../hooks/useLoginModal";
import Input from "components/Inputs/Input";
import Button from "shared/Button/Button";

interface FormData {
  name: string;
  email: string;
  password: string;
  tel: string;
}

const RegisterModal: React.FC = () => {
  const registerModal = useRegisterModal();
  const loginModal = useLoginModal();
  const [isLoading, setIsLoading] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [hasAgreed, setHasAgreed] = useState(false);
  const [hasRead, setHasRead] = useState(false);
  const termsRef = useRef<HTMLDivElement>(null);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      tel: "",
    },
  });

  const password = watch("password");
  const email = watch("email");

  const handleScroll = () => {
    if (termsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = termsRef.current;
      // Check if the user has scrolled to the bottom
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setHasRead(true); // Enable the agree button
      }
    }
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    if (!hasAgreed) {
      toast.error("You must read and agree to the Buyer Agreement to register.");
      return;
    }

    if (password.length >= 6 && email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      setIsLoading(true);

      axios
        .post("/auth/register", data, {
          headers: { Authorization: localStorage.getItem("token") },
        })
        .then((data) => {
          registerModal.onClose();
          toast.success("Account registered successfully");
          loginModal.onOpen();
          setIsLoading(false);
          reset();
        })
        .catch((error) => {
          if (Number(error.status) === 401) {
            toast.error("Invalid email address");
          } else {
            toast.error("Something went wrong");
          }
          setIsLoading(false);
        });
    } else {
      toast.error(
        "Password must be at least 8 characters long and the email address must be valid!"
      );
    }
  };

  const bodyContent = (
    <div className="flex flex-col gap-4">
      <Heading title="Welcome!" subtitle="Create an account!" />
      <Input
        id="email"
        label="Email Address"
        type="email"
        disabled={isLoading}
        errors={errors}
        register={register}
        required
      />
      <Input
        id="name"
        label="Name"
        type="text"
        disabled={isLoading}
        errors={errors}
        required
        register={register}
      />
      <Input
        id="password"
        label="Password"
        type="password"
        disabled={isLoading}
        errors={errors}
        register={register}
        required
      />
      <Input
        id="tel"
        label="Phone Number"
        type="tel"
        disabled={isLoading}
        errors={errors}
        register={register}
        required
      />
      <div className="flex flex-col gap-2">
        <button
          type="button"
          onClick={() => setShowTerms(!showTerms)}
          className="text-sm text-blue-500 hover:underline"
        >
          {showTerms ? "Hide Buyer Agreement" : "Read Buyer Agreement"}
        </button>
        {showTerms && (
          <div
            ref={termsRef}
            onScroll={handleScroll}
            className="p-4 bg-gray-100 rounded-lg text-sm max-h-40 overflow-y-auto"
          >
            <h2 className="font-bold text-lg mb-2">BUYER AGREEMENT</h2>
            <p>
              This Buyer Agreement (the “Agreement”) is entered into as of the date of account
              creation on the TryBuy.club platform (the “Effective Date”) by and between:
            </p>
            <ol className="list-decimal pl-5">
              <li>
                TryBuy.club, an online platform connecting property buyers and hotels offering
                investment opportunities in hotel properties (the “Platform”), and
              </li>
              <li>
                The Buyer, the individual or entity creating an account to use the Platform (the
                “Buyer”).
              </li>
            </ol>
            <p>
              By registering an account on TryBuy.club, you acknowledge that you have read,
              understood, and agree to be bound by the terms and conditions of this Agreement.
            </p>

            <h3 className="font-bold mt-4">1. Purpose of the Agreement</h3>
            <p>This Agreement governs the Buyer’s use of the TryBuy.club platform to:</p>
            <ul className="list-disc pl-5">
              <li>Browse hotel properties available for investment purposes.</li>
              <li>Make property purchase inquiries.</li>
              <li>
                Book stays at properties listed on the Platform for trial purposes before
                purchasing.
              </li>
            </ul>

            <h3 className="font-bold mt-4">2. Account Creation</h3>
            <h4 className="font-bold">2.1. Registration:</h4>
            <p>
              The Buyer must create a free account to access the Platform's features, including
              viewing listings, booking stays, and submitting property purchase requests.
            </p>
            <h4 className="font-bold">2.2. Accuracy of Information:</h4>
            <p>
              The Buyer agrees to provide accurate and up-to-date information during the
              registration process.
            </p>
            <h4 className="font-bold">2.3. Account Security:</h4>
            <p>
              The Buyer is responsible for maintaining the confidentiality of their account login
              credentials. TryBuy.club is not liable for any unauthorized access resulting from the
              Buyer’s failure to secure their account information.
            </p>

            <h3 className="font-bold mt-4">3. Use of the Platform</h3>
            <h4 className="font-bold">3.1. Browsing and Booking:</h4>
            <p>
              The Buyer may browse available properties and book stays at listed hotel properties
              through the Platform.
            </p>
            <h4 className="font-bold">3.2. Purchase Requests:</h4>
            <p>
              The Buyer may submit expressions of interest to purchase a listed property directly to
              the seller via the Platform.
            </p>
            <h4 className="font-bold">3.3. Exclusions:</h4>
            <p>
              The Buyer acknowledges that payments for property purchases and any rental agreements
              are handled outside the Platform. Only booking fees and purchase requests are
              facilitated through the Platform.
            </p>

            <h3 className="font-bold mt-4">4. Notification of Property Purchase</h3>
            <h4 className="font-bold">4.1. Obligation to Notify:</h4>
            <p>
              The Buyer is required to notify TryBuy.club in writing if a property purchase is
              completed as a result of using the Platform. Notification must be submitted within 7
              days of the transaction.
            </p>
            <h4 className="font-bold">4.2. Failure to Notify:</h4>
            <p>
              Failure to notify TryBuy.club of a completed transaction will result in a penalty fee
              of $500, which must be paid within 10 days of being invoiced.
            </p>

            <h3 className="font-bold mt-4">5. Fees and Payments</h3>
            <h4 className="font-bold">5.1. Platform Fees:</h4>
            <p>
              Creating an account and browsing properties is free for Buyers. Booking fees are
              calculated at the time of reservation and are payable directly to the Hotel.
            </p>
            <h4 className="font-bold">5.2. Payment Obligations:</h4>
            <p>
              The Buyer agrees to fulfill all payment obligations promptly and acknowledges that
              failure to pay may result in suspension or termination of their account.
            </p>

            <h3 className="font-bold mt-4">6. Buyer’s Responsibilities</h3>
            <h4 className="font-bold">6.1. Compliance:</h4>
            <p>
              The Buyer agrees to comply with all applicable laws and regulations when using the
              Platform and conducting actions.
            </p>
            <h4 className="font-bold">6.2. Fair Use:</h4>
            <p>
              The Buyer shall not misuse the Platform or engage in activities that may harm the
              reputation or functionality of TryBuy.club.
            </p>
            <h4 className="font-bold">6.3. Honesty and Transparency:</h4>
            <p>
              The Buyer must act in good faith when engaging with sellers and submitting inquiries
              or purchase requests.
            </p>

            <h3 className="font-bold mt-4">7. Limitation of Liability</h3>
            <p>
              TryBuy.club provides the Platform as a marketplace and is not a party to any
              transaction between Buyers and Sellers. The Platform is not responsible for:
            </p>
            <ul className="list-disc pl-5">
              <li>Verifying the accuracy of property listings.</li>
              <li>Guaranteeing the quality or legality of properties listed.</li>
              <li>Resolving disputes between Buyers and Sellers.</li>
            </ul>

            <h3 className="font-bold mt-4">8. Termination of Agreement</h3>
            <h4 className="font-bold">8.1. Voluntary Termination:</h4>
            <p>
              The Buyer may terminate this Agreement at any time by closing their account on the
              Platform.
            </p>
            <h4 className="font-bold">8.2. Termination by TryBuy.club:</h4>
            <p>
              TryBuy.club may suspend or terminate the Buyer’s account if the Buyer breaches this
              Agreement or engages in activities that harm the Platform or its users.
            </p>

            <h3 className="font-bold mt-4">9. Data Protection and Privacy</h3>
            <p>
              TryBuy.club will process the Buyer’s personal information in accordance with the
              Privacy Policy. By using the Platform, the Buyer consents to the collection and use of
              their information as described therein.
            </p>

            <h3 className="font-bold mt-4">10. Governing Law and Dispute Resolution</h3>
            <p>
              This Agreement shall be governed by the laws of the European Union. Any disputes
              arising from or related to this Agreement shall be resolved in the courts of the
              European Union.
            </p>

            <h3 className="font-bold mt-4">11. Miscellaneous</h3>
            <h4 className="font-bold">11.1. Entire Agreement:</h4>
            <p>
              This Agreement constitutes the entire understanding between TryBuy.club and the Buyer
              regarding the subject matter and supersedes all prior agreements, representations, or
              understandings.
            </p>
            <h4 className="font-bold">11.2. Amendments:</h4>
            <p>
              TryBuy.club reserves the right to amend this Agreement at any time. Updated terms will
              be posted on the Platform, and continued use constitutes acceptance of the revised
              Agreement.
            </p>
            <h4 className="font-bold">11.3. Severability:</h4>
            <p>
              If any provision of this Agreement is found to be unenforceable, the remaining
              provisions shall remain in full force and effect.
            </p>

            <h3 className="font-bold mt-4">Contact Information</h3>
            <p>
              For any questions or concerns regarding this Agreement, please contact us:
              <br />
              Email: support@trybuy.club
              <br />
              WhatsApp: +12093427780; +359885368594
            </p>
          </div>
        )}
        {showTerms && (
          <button
            type="button"
            onClick={() => {
              setHasAgreed(true);
              setTimeout(() => {
                setShowTerms(false);
              }, 350);
            }}
            disabled={!hasRead}
            className={`mt-2 p-2 text-sm ${
              hasRead ? "bg-green-500 text-white" : "bg-gray-300 text-gray-500"
            } rounded`}
          >
            {hasAgreed ? "Agreed" : "I Agree to the Buyer Agreement"}
          </button>
        )}
      </div>
    </div>
  );

  const footerContent = (
    <div className="flex flex-col gap-4 mt-3 ">
      <hr />
      <div className="text-neutral-500 text-center mt-4 font-light">
        <div className="flex justify-center flex-row items-center gap-2">
          <div>Already have an account?</div>
          <div
            onClick={() => {
              registerModal.onClose();
              loginModal.onOpen();
            }}
            className="text-neutral-800 cursor-pointer hover:underline"
          >
            Log in
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      disabled={isLoading}
      isOpen={registerModal.isOpen}
      title="Registration"
      actionLabel="Register"
      onClose={registerModal.onClose}
      onSubmit={handleSubmit(onSubmit)}
      body={bodyContent}
      footer={footerContent}
    />
  );
};

export default RegisterModal;
