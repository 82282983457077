import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

export interface PageSellerGuidesProps {
  className?: string;
}

const TermsAndConditions: FC<PageSellerGuidesProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageSellerGuides overflow-hidden relative ${className}`}
      data-nc-id="PageSellerGuides"
    >
           {" "}
      <Helmet>
                <title>About Us - TryBuy.club</title>     {" "}
      </Helmet>
            <BgGlassmorphism />     {" "}
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
               {" "}
        <div className="py-16 lg:py-28 space-y-8 lg:space-y-12">
                   {" "}
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">Terms and Conditions</h2>
          <p>
            Welcome to TryBuy.club. By accessing and using our platform, you agree to comply with
            and be bound by the following terms and conditions. If you do not agree with these
            terms, please do not use our platform.
          </p>
          <h3>1. General Information</h3>
          <p>
            TryBuy.club is an online platform that connects buyers and sellers of hotel properties.
            We offer services for hotel owners to list properties for sale and for investors to
            browse and purchase hotel investment properties.
          </p>
          <h3>2. Registration and Fees</h3>
          <p>
            <strong>Hotel Registration Fee:</strong>A one-time registration fee of $100 is required
            for hotel owners to create a profile and list their properties on TryBuy.club. This fee
            includes the representation of your hotel and the listing of up to 12 properties for
            sale.
          </p>
          <p>
            <strong>Additional Listings:</strong>
            Once the 12 free listings have been exhausted, hotel owners must purchase additional
            listing packages to continue listing properties on the platform.
          </p>
          <p>
            <strong>Featured Listings:</strong>
            Hotel owners can pay additional fees to promote their listings to Featured Listings,
            placing them at the top of search results for increased visibility.
          </p>
          <p>
            <strong>Payment for Listings and Features:</strong>
            Payments for registration, additional listings, and Featured Listings must be made
            immediately through the platform. These payments are processed directly through the
            platform.
          </p>
          <h3>3. Hotel and Property Listings</h3>
          <p>
            <strong>Eligibility:</strong>
            Only hotel properties are allowed to be listed on the platform. The listings must
            accurately represent the hotel or hotel property being sold. All properties listed must
            comply with local laws and regulations.
          </p>
          <p>
            <strong>Property Listings:</strong>
            Hotel owners are responsible for the accuracy and completeness of their property
            listings. Listings must include high-quality images, detailed descriptions, and all
            relevant information. TryBuy.club reserves the right to remove or edit any listing that
            does not comply with our guidelines.
          </p>
          <p>
            <strong>Pricing:</strong>
            Hotel owners are free to set their own prices for properties listed on the platform.
            TryBuy.club does not control or participate in pricing decisions. All payments for
            property transactions are handled directly between the buyer and the hotel seller.
          </p>
          <h3>4. Payments</h3>
          <p>
            <strong>Payment for Listings:</strong>
            Payments for registration, additional listings, and Featured Listings must be made
            immediately through the platform. All transactions are processed securely.
          </p>
          <p>
            <strong>Commission Fees:</strong>
            If a sale is completed through the platform, hotel owners will pay a commission of 1% of
            the final sale price to TryBuy.club. This commission is due within 15 days after the
            transaction is completed, and the hotel owner will receive an invoice for the
            commission. Payment of the commission will be made via bank transfer.
          </p>
          <h3>5. Platform Usage</h3>
          <p>
            <strong>Buyers:</strong>
            Buyers must create an account to browse listings and make inquiries. Buyers can book
            stays at hotels through TryBuy.club and express interest in purchasing properties listed
            on the platform. All transactions are made directly between the buyer and the hotel
            owner.
          </p>
          <p>
            <strong>Notification Requirement:</strong> Buyers are obligated to notify TryBuy.club if
            they complete a property purchase through the platform. This notification must be made
            in writing, within 7 days after the purchase, to ensure proper tracking and commission
            processing.
          </p>
          <p>
            <strong>Penalty for Non-Notification:</strong> Failure to notify TryBuy.club within the
            specified 7-day period will result in a penalty fee of $500. This penalty will be
            charged to the buyer's account and must be paid before any further transactions can be
            conducted on the platform.
          </p>
          <p>
            <strong>Sellers:</strong>
            Hotel owners must create an account to list their properties and manage their listings.
            Hotel owners are responsible for updating and managing their property information.
          </p>
          <p>
            <strong>Account Security:</strong>
            You agree to keep your account information confidential and are responsible for any
            activity that occurs under your account. Notify TryBuy.club immediately if you suspect
            any unauthorized access to your account.
          </p>
          <h3>6. Featured Listings and Promotion</h3>
          <p>
            <strong>Featured Listings:</strong>
            Hotel owners can opt to pay for Featured Listings to move their properties to the top of
            search results. Featured Listings will remain in this premium position for the duration
            of the paid period.
          </p>
          <p>
            <strong>Advertising and Promotions:</strong>
            TryBuy.club reserves the right to run promotional campaigns or advertisements related to
            the platform or third-party services. Advertisers must comply with TryBuy.club’s
            advertising guidelines.
          </p>
          <h3>7. Platform Limitations and Liability</h3>
          <p>
            <strong>No Guarantee of Sales:</strong>
            TryBuy.club does not guarantee that any hotel property listed on the platform will be
            sold. The platform merely serves as a marketplace for buyers and sellers to connect.
          </p>
          <p>
            <strong>Limitation of Liability:</strong>
            TryBuy.club is not liable for any damages, losses, or disputes arising from transactions
            between buyers and sellers. All transactions are the responsibility of the parties
            involved.
          </p>
          <h3>8. User Conduct</h3>
          <p>
            <strong>Prohibited Activities:</strong>
            Users agree not to engage in any activities that may harm, disrupt, or interfere with
            the operation of TryBuy.club. This includes, but is not limited to, fraudulent listings,
            misleading information, or illegal activities.
          </p>
          <p>
            <strong>Account Termination:</strong>
            TryBuy.club reserves the right to suspend or terminate the account of any user who
            violates these Terms and Conditions, without refunding any fees.
          </p>
          <h3>9. Privacy Policy</h3>
          <p>
            TryBuy.club respects your privacy. Your personal information is only used in accordance
            with our Privacy Policy. By using the platform, you consent to the collection and use of
            your information as described in the Privacy Policy.
          </p>
          <h3>10. Termination of Service</h3>
          <p>
            TryBuy.club reserves the right to suspend or terminate any account or service at its
            sole discretion, particularly if a user’s actions are deemed to harm the platform or its
            users.
          </p>
          <h3>11. Governing Law</h3>
          <p>
            These Terms and Conditions are governed by the laws of [Country/Region], and any
            disputes will be resolved in the appropriate courts within that jurisdiction.
          </p>
          <h3>12. Changes to Terms</h3>
          <p>
            TryBuy.club reserves the right to update or modify these Terms and Conditions at any
            time. Users will be notified of any changes via email or on the platform. Continued use
            of the platform after changes are made constitutes acceptance of the updated terms.
          </p>
          <h3>13. Contact Information</h3>
          <p>
          If you have any questions or concerns about these Terms and Conditions, please contact us at:
          </p>
                 {" "}
        </div>
               {" "}
        <div className="relative py-16">
                    <BackgroundSection />
                    <SectionClientSay uniqueClassName="PageSellerGuides_" />       {" "}
        </div>
                <SectionSubscribe2 />     {" "}
      </div>
         {" "}
    </div>
  );
};

export default TermsAndConditions;
