import React, { FC } from "react";
import imagePng from "images/banner-top-image.jpg";
import HeroRealEstateSearchForm from "components/HeroSearchForm/(real-estate-search-form)/HeroRealEstateSearchForm";
import LanguageSwitcher from "components/GoogleTranslate/LanguageSwitcher";
import GTranslate from "components/GoogleTranslate/gTranslate";

export interface SectionHero2Props {
  className?: string;
  children?: React.ReactNode;
}

const SectionHero2: FC<SectionHero2Props> = ({ className = "", children }) => {
  return (
    <div className={`nc-SectionHero2 relative ${className}`} data-nc-id="SectionHero2">
      <div className="absolute inset-y-0 w-5/6 xl:w-3/4 right-0 flex-grow">
        <img className="absolute inset-0 object-cover w-full h-full" src={imagePng} alt="hero" />
      </div>
      <div className="relative py-14 lg:py-20">
        <div className="relative inline-flex">
          <div className="w-screen right-20 md:right-52 inset-y-0 absolute bg-primary-500"></div>
          <div className="relative max-w-3xl inline-flex flex-shrink-0 flex-col items-start py-16 sm:py-20 lg:py-24 space-y-8 sm:space-y-10 text-white">
            {children ? (
              children
            ) : (
              <h2 className="font-semibold text-4xl !leading-[110%] w-[34%] text-[2.05rem] leading-[2.5rem] md:w-[75%] md:text-5xl md:leading-normal xl:text-5xl">
                Try, Buy, and Earn! <br />{" "}
                <span className="w-32">Own an Investment Hotel Property with High Return!</span>
              </h2>
            )}
          </div>

          {/* <LanguageSwitcher /> */}
          <GTranslate />
        </div>
        <div className="hidden lg:block lg:mt-20 w-full">
          <HeroRealEstateSearchForm />
        </div>
      </div>
    </div>
  );
};

export default SectionHero2;
