import rightImg from "images/about-hero-right.png";
import React, { FC, useContext, useEffect, useState } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionHeroLeft from "./SectionHeroLeft";
import SectionStatisticSellers from "./SectionStatisticSellers";
import { UserContext } from "UserContext";

export interface PageCheckAccountProps {
  className?: string;
}

const PageCheckAccount: FC<PageCheckAccountProps> = ({ className = "" }) => {
  const { user } = useContext(UserContext);
  const [isApproved, setIsApproved] = useState<Number>(0);
  useEffect(() => {
    setIsApproved(user?.isApproved || 0);
    if (isApproved === 1) {
      window.location.href = "/";
    }
  }, [user]);
  return (
    <div className={`nc-PageAbout overflow-hidden relative ${className}`} data-nc-id="PageAbout">
      <Helmet>
        <title>Check Account - TryBuy.club</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-12 lg:space-y-20 text-center">
        <div className="bg-gray-100 rounded-lg shadow-md p-8 lg:p-12 max-w-4xl mx-auto">
          <h2 className="text-2xl lg:text-3xl font-semibold text-gray-800 mb-6">
            Thank You for Registering!
          </h2>
          <p className="text-gray-600 leading-relaxed">
            We are excited to have you on board! Your account is currently under review by our team
            to ensure all necessary information is verified and meets our platform standards.
          </p>
          <p className="text-gray-600 leading-relaxed mt-4">
            Once the review is completed, you will need to make the required payment to activate
            your account. After activation, you’ll have full access to list properties and enjoy all
            the benefits of our platform.
          </p>
          <p className="text-gray-600 leading-relaxed mt-4">
            If you have any questions or need assistance during this process, feel free to contact
            our support team at{" "}
            <a href="mailto:support@example.com" className="text-blue-500 hover:underline">
              support@trybuy.club
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageCheckAccount;
